<template>
  <div v-background-3 class="rounded shadow-sm mr-lg-3">
    <h4 class="ml-3 mt-3">Affaires à enjeux radio</h4>
    <div class="ml-3 mr-3">
      <div class="btn btn-outline-primary btn-sm m-1" :class="{ active: tab === 'TOUT' }" @click="tab = 'TOUT'">
        TOUT
      </div>
      <div @click="tab = 'AT'" class="btn btn-outline-primary btn-sm m-1" :class="{ active: tab === 'AT' }">AT</div>
      <div @click="tab = 'ETUDE'" class="btn btn-outline-primary btn-sm m-1" :class="{ active: tab === 'ETUDE' }">
        ETUDE
      </div>
      <div @click="tab = 'SCAN'" class="btn btn-outline-primary btn-sm m-1" :class="{ active: tab === 'SCAN' }">
        SCAN
      </div>
      <div @click="tab = 'CND'" class="btn btn-outline-primary btn-sm m-1" :class="{ active: tab === 'CND' }">CND</div>
      <div
        @click="tab = 'INSPECTION'"
        class="btn btn-outline-primary btn-sm m-1"
        :class="{ active: tab === 'INSPECTION' }"
      >
        INSPECTION
      </div>
    </div>
    <div v-for="affaire in affairesFiltered" :key="affaire.id">
      <affaires-item :data="affaire"></affaires-item>
    </div>
  </div>
</template>
<script>
import AffairesItem from "./AffairesItem.vue";
import { mapMultiRowFields } from "vuex-map-fields";

export default {
  components: { AffairesItem },
  data() {
    return {
      tab: "TOUT",
    };
  },
  mounted() {},
  computed: {
    ...mapMultiRowFields("affaires", ["affaires"]),
    affairesFiltered() {
      if (this.tab == "TOUT") return this.affaires;
      if (this.tab == "AT") return this.affaires.filter((affaire) => affaire.affaire_activite_id === 1);
      if (this.tab == "ETUDE") return this.affaires.filter((affaire) => affaire.affaire_activite_id === 2);
      if (this.tab == "SCAN") return this.affaires.filter((affaire) => affaire.affaire_activite_id === 3);
      if (this.tab == "CND") return this.affaires.filter((affaire) => affaire.affaire_activite_id === 4);
      if (this.tab == "INSPECTION") return this.affaires.filter((affaire) => affaire.affaire_activite_id === 5);
      return this.affaires;
    },
  },
  methods: {},
};
</script>
<style></style>
