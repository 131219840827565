<template>
  <div class="w-100 h-100">
    <div class="d-flex flex-column flex-lg-row w-100 mb-4 h-100" v-if="!loading">
      <affaires class="flex-shrink-0 mb-2" :style="styleLeftBarLg"></affaires>
      <collaborateurs class="mt-3 mt-lg-0" ref="collabsElement"></collaborateurs>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import Affaires from "./Affaires.vue";
import Collaborateurs from "./Collaborateurs.vue";
import Loader from "@/components/bases/Loader.vue";
import screenWidth from "@/mixins/screenWidth";

export default {
  components: {
    Loader,
    Affaires,
    Collaborateurs,
  },
  mixins: [screenWidth],
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    Promise.all([this.getData(), this.getDosi(), this.getCollabs(), this.getAffaires()]).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    styleLeftBarLg() {
      if (this.w > 992) return "min-width:350px;max-height:100vh;overflow:auto;position:sticky;top:0;z-index:1020;";
      else return "max-height:50vh;overflow:auto;";
    },
  },
  methods: {
    ...mapActions({
      getData: "affaires/getData",
      getDosi: "dosimetrie/getAll",
      getCollabs: "collaborateurs/getCollaborateursPcr",
      getAffaires: "affaires/getAffairesPcr",
    }),
  },
};
</script>
<style></style>
