<template>
  <div class="rounded p-2 m-1 w-100" v-background-1 style="min-width: 9rem">
    <small>
      <div v-if="loading">Chargement...</div>
      <div v-if="!loading">
        <div class="d-flex align-items-center justify-content-between" v-if="data && data.mois">
          <strong>{{ data.mois.format("MMMM YYYY") }}</strong>
        </div>
        <div class="d-flex justify-content-between">
          <span class="text-muted mr-1">prev.</span>
          <span v-if="data && data.prev">{{ data.prev.toFixed(3) }} mSv</span>
          <span v-else>0.000 mSv</span>
        </div>
        <div class="d-flex justify-content-between" v-if="data.mois <= $moment()">
          <span class="text-muted mr-1">dose</span>
          <span v-if="data && data.dose">{{ data.dose.toFixed(3) }} mSv</span>
          <span v-else>0.000 mSv</span>
        </div>
        <div v-if="data.cumul >= 0 && data.cumul < 2 && data.mois <= $moment()" class="d-flex justify-content-between">
          <span class="text-muted mr-1">12 mois</span>
          <span class="text-success" v-if="data && data.cumul">{{ data.cumul.toFixed(3) }} mSv</span>
          <span v-else>0.000 mSv</span>
        </div>
        <div v-if="data.cumul >= 2 && data.cumul < 4 && data.mois <= $moment()" class="d-flex justify-content-between">
          <span class="text-muted mr-1">12 mois</span>
          <span class="text-primary" v-if="data && data.cumul">{{ data.cumul.toFixed(3) }} mSv</span>
        </div>
        <div v-if="data.cumul >= 4 && data.mois <= $moment()" class="d-flex justify-content-between">
          <span class="text-muted mr-1">12 mois</span>
          <span class="text-danger" v-if="data && data.cumul">{{ data.cumul.toFixed(3) }} mSv</span>
        </div>
        <div
          v-if="data.cumulPrev >= 0 && data.cumulPrev < 2 && data.mois > $moment()"
          class="d-flex justify-content-between"
        >
          <span class="text-muted mr-1">12 mois</span>
          <span class="text-success" v-if="data && data.cumulPrev">{{ data.cumulPrev.toFixed(3) }} mSv</span>
        </div>
        <div
          v-if="data.cumulPrev >= 2 && data.cumulPrev < 4 && data.mois > $moment()"
          class="d-flex justify-content-between"
        >
          <span class="text-muted mr-1">12 mois</span>
          <span class="text-primary" v-if="data && data.cumulPrev">{{ data.cumulPrev.toFixed(3) }} mSv</span>
        </div>
        <div v-if="data.cumulPrev >= 4 && data.mois > $moment()" class="d-flex justify-content-between">
          <span class="text-muted mr-1">12 mois</span>
          <span class="text-danger" v-if="data && data.cumulPrev">{{ data.cumulPrev.toFixed(3) }} mSv</span>
        </div>
      </div>
    </small>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["doses", "mois"],
  data() {
    return {
      loading: false,
      data: {
        mois: null,
        dose: null,
        prev: null,
        cumul: null,
        cumulPrev: null,
      },
    };
  },
  methods: {
    calc: function () {
      var fn = function (_doses, _dt1, _dt2, _dt3) {
        var _cumulDose = 0;
        var _cumulPrev = 0;
        var _dose = 0;
        var _prev = 0;

        for (let index = 0; index < _doses.length; index++) {
          const dose = _doses[index];
          const dt = new Date(Date.parse(dose.mois.replace(/ /g, "T"))).getTime();

          if (dt >= _dt1 && dt <= _dt2) {
            if (dose.dosi_type_id === 1) _cumulDose = _cumulDose + dose.dosi_dose;
            if (dose.dosi_type_id === 4) _cumulPrev = _cumulPrev + dose.dosi_dose;
          }

          if (dt >= _dt3 && dt <= _dt2) {
            if (dose.dosi_type_id === 1) _dose = _dose + dose.dosi_dose;
            if (dose.dosi_type_id === 4) _prev = _prev + dose.dosi_dose;
          }
        }

        return {
          dose: _dose,
          prev: _prev,
          cumul: _cumulDose,
          cumulPrev: _cumulPrev,
        };
      };

      const mois = this.mois;
      const dt1 = this.$moment(mois).subtract(12, "months").startOf("month").toDate().getTime();
      const dt2 = this.$moment(mois).endOf("month").toDate().getTime();
      const dt3 = this.$moment(mois).startOf("month").toDate().getTime();

      let data = [];
      this.doses.forEach((dose) => {
        data.push({
          mois: dose.dosi_date_fin,
          dosi_dose: dose.dosi_dose,
          dosi_type_id: dose.dosi_type_id,
        });
      });

      this.loading = true;

      this.$worker
        .run(fn, [data, dt1, dt2, dt3])
        .then((resp) => {
          this.data = {
            mois: this.$moment(mois).startOf("month"),
            dose: resp.dose,
            prev: resp.prev,
            cumul: resp.cumul,
            cumulPrev: resp.cumulPrev,
          };
          this.loading = false;
        })
        .catch(console.error);
    },
  },
  mounted() {
    this.calc();
  },
  watch: {
    doses: function () {
      this.calc();
    },
  },
};
</script>
<style></style>
