<template>
  <div
    class="btn d-flex align-items-center justify-content-center cursor-pointer"
    :class="bg"
    @click="$emit('click')"
  >
    <BaseIcon
      :name="name"
      :width="size"
      :height="size"
      :color="stroke"
      class="d-flex"
      v-if="!loading"
    ></BaseIcon>
    <BaseIcon
      name="loader"
      :width="size"
      :height="size"
      :color="stroke"
      class="d-flex"
      :class="spinnerRotate"
      v-if="loading"
    ></BaseIcon>
    <span class="ml-1" :style="{color: stroke}">{{text}}</span>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: { BaseIcon },
  name: "ButtonCircle",
  props: {
    name: {
      type: String,
      default: "box"
    },
    text: {
      type: String,
      default: "text"
    },
    size: {
      type: String,
      default: "16"
    },
    colorClass: {
      type: String,
      default: "text-primary"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bg: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "btn-circle-text-light";
      } else {
        return "btn-circle-text-dark";
      }
    },
    stroke: function() {
      if (this.name === "x") {
        return "#dc3545";
      }
      if (this.name === "download") {
        return "#28a745";
      }
      if (this.name === "save") {
        return "#dc3545";
      }
      if (this.name === "trash-2") {
        return "#dc3545";
      }
      if (!this.$store.getters["colors/darkMode"]) {
        return "#007bff";
      } else {
        return "#007bff";
      }
    },
    spinnerRotate() {
      return this.loading ? "icon-rotate" : "";
    }
  }
};
</script>
<style lang="css">
.btn-circle-text-light {
  border-radius: 30px;
  border: 0;
  outline: 0;
  background-color: none;
}

.btn-circle-text-light:hover {
  background-color: #eeeff0;
}

.btn-circle-text-dark {
  border-radius: 30px;
  border: 0;
  outline: 0;
  background-color: none;
}

.btn-circle-text-dark:hover {
  background-color: #525c65;
}
</style>
