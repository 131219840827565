<template>
  <div class="w-100">
    <collaborateurs-chart :doses="doses"></collaborateurs-chart>
    <siseri-uploader
      :id="'siseri_uploader'"
      url="/upload/document"
      file_system="Radioprotection"
      :file_field="'CSV'"
      :file_key="1"
      file_accepted=".csv"
    ></siseri-uploader>
    <div class="d-flex w-100 justify-content-between">
      <h4 class="m-0">Collaborateurs catégorie A ou B</h4>
      <button class="btn btn-primary ml-auto" @click="sort">Trier</button>
    </div>
    <div class="w-100 mt-3" id="list">
      <div v-for="collaborateur in collaborateursPcr" :key="collaborateur.id" class="w-100">
        <collaborateurs-item :collaborateur="collaborateur" :infos="false"></collaborateurs-item>
      </div>
    </div>
  </div>
</template>
<script>
import CollaborateursItem from "./CollaborateursItem.vue";
import CollaborateursChart from "./CollaborateursChart.vue";
import date from "@/mixins/date.js";
import { mapMultiRowFields } from "vuex-map-fields";
import SiseriUploader from "./SiseriUploader.vue";

export default {
  components: { CollaborateursItem, CollaborateursChart, SiseriUploader },
  data() {
    return {};
  },
  mixins: [date],
  computed: {
    ...mapMultiRowFields("collaborateurs", ["collaborateursPcr"]),
    ...mapMultiRowFields("dosimetrie", ["doses"]),
  },
  methods: {
    sort: function () {
      const list = document.getElementById("list");
      const items = list.childNodes;
      let itemsArr = [];

      // Get all items in list
      for (var i in items) {
        if (items[i].nodeType == 1) itemsArr.push(items[i]);
      }

      // Sort by value
      itemsArr.sort(function (a, b) {
        const _a = parseFloat(a.lastChild.attributes.val.value);
        const _b = parseFloat(b.lastChild.attributes.val.value);
        return _a == _b ? 0 : _a < _b ? 1 : -1;
      });

      for (i = 0; i < itemsArr.length; ++i) {
        list.appendChild(itemsArr[i]);
      }
    },
  },
};
</script>
<style></style>
