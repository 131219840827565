<template>
  <ContainerCard class="d-flex flex-column w-100 rounded shadow-sm mb-3" v-background-3>
    <div class="pl-3 pt-3">
      <h6 class="m-0">Dosimétrie collective</h6>
    </div>
    <div id="chart1" class="pl-3 pr-3" v-if="data">
      <apexchart type="line" height="360" :options="chartOptions" :series="series"></apexchart>
    </div>
  </ContainerCard>
</template>
<script>
import ContainerCard from "@/components/containers/ContainerCard.vue";
import date from "@/mixins/date.js";

export default {
  components: {
    ContainerCard,
  },
  data() {
    return {
      loading: false,
      chartLoaded: true,
    };
  },
  props: {
    doses: {
      Type: Array,
    },
  },
  methods: {},
  mixins: [date],
  computed: {
    data() {
      let dateStart = this.$moment().subtract(12, "months");
      let dateEnd = this.$moment().add(1, "months");
      let _dose = 0;
      let _prev = 0;
      let _rep = [];

      while (dateEnd > dateStart || dateEnd == dateStart) {
        _dose = 0;
        _prev = 0;

        for (let index = 0; index < this.doses.length; index++) {
          const dose = this.doses[index];
          if (this.dateMoisSystem(dose.dosi_date_fin) === this.dateMoisSystem(dateStart) && dose.dosi_dose) {
            if (dose.dosi_type_id === 1) _dose = _dose + parseFloat(dose.dosi_dose);
            if (dose.dosi_type_id === 4) _prev = _prev + parseFloat(dose.dosi_dose);
          }
        }

        if (_dose > 0) _dose.toFixed(3);
        if (_prev > 0) _prev.toFixed(3);

        _rep.push({
          mois: dateStart.format("MM/YYYY"),
          dose: _dose,
          prev: _prev,
        });

        dateStart.add(1, "month");
      }

      const arrayColumn = (arr, n) => arr.map((x) => x[n]);

      return {
        labels: arrayColumn(_rep, "mois"),
        dataset: arrayColumn(_rep, "dose"),
      };
    },
    series() {
      if (this.data && this.data.dataset) {
        return [
          {
            name: "Doses",
            data: this.data.dataset,
          },
        ];
      }
      return [];
    },
    chartOptions() {
      if (this.data && this.data.dataset) {
        return {
          colors: ["#EB9D00"],
          chart: {
            toolbar: {
              show: false,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
              autoSelected: "zoom",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          theme: {
            mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
          },
          stroke: {
            width: 4,
            curve: "smooth",
          },
          xaxis: {
            categories: this.data.labels,
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(3);
              },
            },
          },
          fill: {
            opacity: 0.8,
          },
          markers: {
            size: 4,
            strokeColors: "#fff",
            strokeWidth: 0,
            hover: {
              size: 7,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(3) + " H.mSv";
              },
            },
          },
        };
      }
      return [];
    },
  },
};
</script>
<style lang="css"></style>
