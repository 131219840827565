<template>
  <div class="mb-3 rounded shadow-sm pt-2" v-background-3>
    <div class="d-flex align-items-center border-toolbar mb-1 pl-3 pr-3">
      <h6 class="m-0">{{ title }}</h6>
      <div class="ml-auto d-flex flex-row pb-1 pt-1 pr-2">
        <label class="m-0" :for="id">
          <button-circle
            name="file-plus"
            size="16"
            v-tooltip="'Ajouter un classeur SISERI CSV'"
          ></button-circle>
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div class="d-flex flex-wrap" :class="{ 'flex-column': rows }">
      <div
        v-show="!loading"
        v-for="file in files"
        :key="file.id"
        class="hover-file rounded"
      >
        <uploader-item
          :file="file"
          :rows="rows"
          @download="process(file)"
          @delete="deleteDocument(file)"
          :loading="loadingProcess"
        ></uploader-item>
      </div>
      <div v-show="!loading" v-for="file in filesToUpload" :key="file.id">
        <uploader-item-new
          :file="file"
          :rows="rows"
          :errors="errors"
        ></uploader-item-new>
      </div>
      <div v-if="files.length < 1" class="text-muted m-auto border-0">
        <small>Aucun fichier</small>
      </div>
      <div v-if="errors" class="mt-2">
        <div v-for="err in errors" :key="err.id" class="text-danger">
          <small>{{ err }}</small>
        </div>
      </div>
    </div>
    <!-- DOC UPLOADER -->
    <input
      :id="id"
      type="file"
      @change="addFileToUpload"
      class="d-none"
      multiple
      :accept="file_accepted"
    />
    <!-- RESPONSE -->
    <div>
      <UploaderPreview @imported="imported" :doses="doses"></UploaderPreview>
    </div>
  </div>
</template>
<script>
import UploaderItem from "./SiseriUploaderItem.vue";
import UploaderItemNew from "./SiseriUploaderItemNew.vue";
import UploaderPreview from "./SiseriUploaderPreview.vue";

import Loader from "@/components/bases/Loader.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

export default {
  name: "Uploader",
  components: {
    UploaderItem,
    Loader,
    UploaderItemNew,
    ButtonCircle,
    UploaderPreview
  },
  data: function() {
    return {
      feedback: {},
      files: [],
      filesToUpload: [],
      loading: false,
      loadingProcess: false,
      progress: 0,
      rows: true,
      errors: [],
      doses: []
    };
  },
  props: {
    id: {
      type: String,
      default: "document"
    },
    url: {
      type: String,
      default: ""
    },
    file_system: {
      type: String,
      default: ""
    },
    file_field: {
      type: String,
      default: ""
    },
    file_key: {
      type: [String, Number],
      default: ""
    },
    file_name: {
      type: String,
      default: "auto"
    },
    file_accepted: {
      type: String,
      default: "image/*, .pdf, .doc, .docx, .xls, .xlsx"
    },
    title: {
      type: String,
      default: "Liste des imports SISERI"
    },
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    allAreSelected() {
      return this.doses.some(dose => dose.selected === true);
    },
    allAreUnSelected() {
      return this.doses.some(dose => dose.selected === false);
    }
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments: function() {
      this.loading = true;
      this.$http
        .get("/trames", {
          params: {
            file_system: this.file_system
          }
        })
        .then(response => {
          this.files = response.data;
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },

    process: function(file) {
      this.loadingProcess = true;
      this.$http
        .get("/trames/import_csv/" + file.id)
        .then(response => {
          this.doses = response.data;
        })
        .finally(() => (this.loadingProcess = false));
    },

    deleteDocument: function(file) {
      file.loadingDelete = true;
      this.$http
        .delete("/trames/" + file.id)
        .then(() => {
          const i = this.files.indexOf(file);
          this.files.splice(i, 1);
        })
        .catch(error => console.log(error));
    },

    addFileToUpload: function(e) {
      let _files = e.target.files || e.dataTransfer.files;
      for (let i = 0; i < _files.length; i++) {
        this.filesToUpload.push({
          file: _files[i],
          name: _files[i].name,
          size: _files[i].size,
          type: _files[i].type,
          progress: 0,
          state: "en attente",
          error: ""
        });
      }
      this.upload();
    },

    upload: async function() {
      this.errors = [];
      for (let i = 0; i < this.filesToUpload.length; i++) {
        let formData = new FormData();
        this.filesToUpload[i].state = "en cours de chargement";
        formData.append("file", this.filesToUpload[i].file);
        formData.append("file_system", this.file_system);
        await this.$http
          .post("/upload/trame", formData, {
            onUploadProgress: progressEvent => {
              if (progressEvent.lengthComputable) {
                this.filesToUpload[i].progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
              }
            }
          })
          .then(() => {
            this.filesToUpload[i].state = "Terminé";
            this.filesToUpload[i].progress = 0;
          })
          .catch(error => this.errors.push(error.response.data.error.file[0]))
          .finally(() => {});
      }
      this.getDocuments();
      this.filesToUpload = [];
    },

    selectAll: function() {
      this.doses.forEach(dose => {
        dose.selected = true;
      });
    },

    unSelectAll: function() {
      this.doses.forEach(dose => {
        dose.selected = false;
      });
    },

    ...mapActions({
      _createDose: "dosimetrie/create"
    }),

    createDose: function(dose) {
      this.feedback = {};
      this.loading = true;
      this._createDose({
        dosi_collaborateur_id: dose.collaborateur.id,
        dosi_dose: dose.dosi_dose.replace(",", "."),
        dosi_date_debut: dose.dosi_date_debut,
        dosi_date_fin: dose.dosi_date_fin,
        dosi_type_id: dose.dosi_type_id,
        dosi_commentaire: dose.dosi_commentaire
      })
        .catch(error => errHandler(error))
        .finally(() => (this.loading = false));
    },
    imported: function() {
      this.doses = [];
    }
  }
};
</script>
<style>
.border-toolbar {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}

.hover-file:hover {
  background-color: rgb(1, 1, 1, 0.03) !important;
}
</style>
