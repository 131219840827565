<template>
  <div class="rounded" v-background-2 v-if="doses.length > 0">
    <div class="pb-3" v-if="!loading">
      <div
        class="d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3"
      >
        <h6 class="m-0">Prévisualiser avant d'importer</h6>
        <div class="d-flex align-items-center">
          <div class="text-primary cursor-pointer mr-3" @click="selectAll">
            Sélectionner tout
          </div>
          <div class="text-primary cursor-pointer" @click="unSelectAll">
            Annuler la sélection
          </div>
          <div class="btn btn-primary ml-3" @click="storeAll" v-if="!loading">
            Envoyer
          </div>
          <div v-if="loading">Chargement...</div>
        </div>
      </div>
      <div v-for="(dose, index) in doses" :key="index" class="pl-3 pr-3">
        <div
          class="mb-2 border rounded p-2 d-flex justify-content-between align-items-center"
          v-background-3
        >
          <div
            class="d-flex justify-content-between align-items-center w-100 text-left"
          >
            <checkbox
              v-if="dose.dosi_collaborateur_id > 0"
              v-model="dose.selected"
              text
              :iconActive="true"
              :errors="feedback.selected"
              style="width:50px;"
            ></checkbox>

            <div class="w-100">
              <div
                class="w-100"
                :class="{ 'text-danger': dose.dosi_collaborateur_id === 0 }"
              >
                <strong>
                  {{ dose.collaborateur.collaborateur_nom }}
                  {{ dose.collaborateur.collaborateur_prenom }}
                </strong>
              </div>
              <span class="text-muted">
                {{ dose.dosi_commentaire }}
              </span>
            </div>

            <div>
              <div class="mr-md-5">
                <div class="text-nowrap">
                  Du {{ dateTimeSimple(dose.dosi_date_debut) }}
                </div>
                <div class="text-nowrap">
                  Au {{ dateTimeSimple(dose.dosi_date_fin) }}
                </div>
              </div>
            </div>

            <h4 class="text-nowrap text-primary m-0 mr-3">
              {{ dose.dosi_dose }} mSv
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Enregistrement des données...</h2>
    </div>
  </div>
</template>
<script>
import Checkbox from "@/components/bases/Checkbox.vue";
import { mapActions } from "vuex";
import date from "@/mixins/date.js";
import Loader from "@/components/bases/Loader.vue";

export default {
  name: "Uploader",
  components: { Checkbox, Loader },
  mixins: [date],
  data: function() {
    return {
      feedback: {},
      loading: false
    };
  },
  props: {
    doses: {
      type: [Object, Array],
      required: false
    }
  },
  computed: {
    allAreSelected() {
      return this.doses.some(dose => dose.selected === true);
    },
    allAreUnSelected() {
      return this.doses.some(dose => dose.selected === false);
    }
  },
  methods: {
    selectAll: function() {
      this.doses.forEach(dose => {
        dose.selected = true;
      });
    },

    unSelectAll: function() {
      this.doses.forEach(dose => {
        dose.selected = false;
      });
    },

    ...mapActions({
      _import: "dosimetrie/import"
    }),

    storeAll: function() {
      this.loading = true;
      this._import({ doses: this.doses })
        .then(() => this.$emit("imported"))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style>
.border-toolbar {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}

.hover-file:hover {
  background-color: rgb(1, 1, 1, 0.03) !important;
}
</style>
