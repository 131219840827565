<template>
  <container-list-extend title="Formation" :val="cumul">
    <template v-slot:title>
      <div class="w-100 d-flex align-items-center p-1">
        <AppAvatar :url="collaborateur.collaborateur_avatar" propSize="3rem" ref="avatar"></AppAvatar>
        <div class="w-100">
          <div class="ml-3 w-100" style="line-height: 1.4">
            <div class="d-flex align-items-center">
              <span class="text-truncate">
                {{ collaborateur.collaborateur_nom }}
                {{ collaborateur.collaborateur_prenom }}
              </span>
            </div>
            <div style>
              <ContratViewer
                class="text-truncate"
                :contrat="collaborateur.collaborateur_contrats"
                :mode="1"
              ></ContratViewer>
            </div>
          </div>
        </div>
        <div class="w-100 mr-3 d-flex justify-content-end">
          <div class="mr-3">
            <span v-if="collaborateur.collaborateur_categorie_id == 1" class="text-muted"> Catégorie A </span>
            <span v-if="collaborateur.collaborateur_categorie_id == 2" class="text-muted">Catégorie B </span>
          </div>
          <div>
            <span class="m-0 mt-1" :class="{ 'text-primary': cumul > 0 }">{{ cumul.toFixed(3) }} mSv </span>
          </div>
        </div>
        <div>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-success m-auto"
            fill="#28a745"
            v-if="collaborateur.collaborateur_actif"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-danger m-auto"
            fill="#dc3545"
            v-if="!collaborateur.collaborateur_actif"
          ></BaseIcon>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="m-3 m-lg-4">
        <div class="d-flex flex-column flex-md-row pr-3 pt-3 mb-3">
          <span
            @click="tab = 'RECAP 12 MOIS'"
            class="cursor-pointer ml-2 ml-md-0 mb-2"
            :class="{ 'border-bottom-graph': tab == 'RECAP 12 MOIS' }"
          >
            <h4 class="mb-1">
              <strong>RECAP 12 MOIS</strong>
            </h4>
          </span>
          <span
            @click="tab = 'LISTE DES DOSES'"
            class="cursor-pointer ml-2 ml-md-3 mb-2"
            :class="{ 'border-bottom-graph': tab == 'LISTE DES DOSES' }"
          >
            <h4 class="mb-1">
              <strong>LISTE DES DOSES</strong>
            </h4>
          </span>
        </div>

        <div class="mb-3" v-if="tab == 'RECAP 12 MOIS'">
          <div class="d-flex flex-column flex-lg-row justify-content-between">
            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(12, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(11, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(10, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(9, 'months').startOf('month')">
            </collaborateurs-item-mois>
          </div>
          <div class="d-flex flex-column flex-lg-row justify-content-between">
            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(8, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(7, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(6, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(5, 'months').startOf('month')">
            </collaborateurs-item-mois>
          </div>
          <div class="d-flex flex-column flex-lg-row justify-content-between">
            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(4, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(3, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(2, 'months').startOf('month')">
            </collaborateurs-item-mois>

            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().subtract(1, 'months').startOf('month')">
            </collaborateurs-item-mois>
          </div>
          <div class="d-flex flex-column flex-lg-row justify-content-between">
            <collaborateurs-item-mois :doses="dosesFiltered" :mois="$moment().add(0, 'months').startOf('month')">
            </collaborateurs-item-mois>
          </div>
        </div>

        <div v-if="tab == 'RECAP 12 MOIS'">
          <button-circle
            name="plus"
            size="30"
            text="Ajouter une dose"
            @click="modeAdd = true"
            v-if="!modeAdd"
          ></button-circle>
          <button-circle name="x" size="30" text="Fermer" v-if="modeAdd" @click="modeAdd = false"></button-circle>
          <div class="text-justify mt-3" v-if="modeAdd">
            <small>
              Attention, les doses que vous allez saisir ne seront pas reconnues en cas d'import SISERI. Pour éviter les
              doublons veuillez vous assurer que ces données ne seront pas présente sur SISERI.
            </small>
          </div>
          <div v-if="modeAdd">
            <base-form class="w-100" :loading="loading" @click="createDose">
              <base-form-row row>
                <base-inputDatePicker
                  v-model="newDose.dosi_date_debut"
                  inputText="Date (début d'exposition)"
                  :errors="feedback.dosi_date_debut"
                ></base-inputDatePicker>
              </base-form-row>
              <base-form-row row>
                <base-inputDatePicker
                  v-model="newDose.dosi_date_fin"
                  inputText="Date (fin d'exposition)"
                  :errors="feedback.dosi_date_fin"
                ></base-inputDatePicker>
              </base-form-row>
              <base-form-row row>
                <base-input
                  v-model="newDose.dosi_dose"
                  inputText="Dose (mSv)"
                  :errors="feedback.dosi_dose"
                ></base-input>
              </base-form-row>
              <base-form-row row>
                <base-select
                  inputText="Type"
                  v-model.number="newDose.dosi_type_id"
                  :options="data.typesDosimetrie"
                  :errors="feedback.dosi_type_id"
                ></base-select>
                <base-select
                  inputText="Affaire"
                  v-model.number="newDose.dosi_affaire_id"
                  :options="affairesList"
                  :errors="feedback.dosi_affaire_id"
                ></base-select>
              </base-form-row>
              <base-form-row row>
                <base-input-area
                  v-model="newDose.dosi_commentaire"
                  text="Commentaire"
                  :errors="feedback.dosi_commentaire"
                ></base-input-area>
              </base-form-row>
            </base-form>
          </div>
        </div>
        <div>
          <div v-if="tab == 'LISTE DES DOSES'">
            <div v-for="dose in dosesFiltered" :key="dose.id">
              <div class="w-100 rounded mb-1 p-2" v-background-1>
                <small>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <div>
                        {{ $moment(dose.dosi_date_debut).format("DD/MM/YYYY HH:MM") }} -
                        {{ $moment(dose.dosi_date_fin).format("DD/MM/YYYY HH:MM") }}
                      </div>
                      <div>
                        <description-from-list
                          class="text-primary"
                          :data="affairesList"
                          :value="dose.dosi_affaire_id"
                          text="Aucune affaire"
                        ></description-from-list>
                      </div>
                      <div>Commentaire : {{ dose.dosi_commentaire }}</div>
                    </div>
                    <div>
                      <h5 class="m-0 text-muted" v-if="dose.dosi_dose == 0">{{ dose.dosi_dose }} mSv</h5>
                      <h5 class="m-0" v-if="dose.dosi_dose > 0 && dose.dosi_dose < 0.5">{{ dose.dosi_dose }} mSv</h5>
                      <h5 class="m-0 text-danger" v-if="dose.dosi_dose >= 0.5">{{ dose.dosi_dose }} mSv</h5>
                      <div>
                        <description-from-list
                          :data="data.typesDosimetrie"
                          :value="dose.dosi_type_id"
                        ></description-from-list>
                      </div>
                    </div>
                  </div>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </container-list-extend>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters, mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import AppAvatar from "@/components/bases/Avatar.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import ButtonCircle from "@/components/bases/ButtonCircleText.vue";
import ContratViewer from "@/components/collaborateurs/viewers/ContratViewer.vue";
import date from "@/mixins/date.js";
import CollaborateursItemMois from "./CollaborateursItemMois.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";

export default {
  components: {
    ContainerListExtend,
    AppAvatar,
    BaseIcon,
    ContratViewer,
    CollaborateursItemMois,
    ButtonCircle,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseInput,
    BaseSelect,
    DescriptionFromList,
  },
  data() {
    return {
      loading: false,
      feedback: {},
      tag: "liste",
      cumul: 0,
      modeAdd: false,
      modeShowDoses: false,
      tab: "RECAP 12 MOIS",
      newDose: {
        dosi_type_id: null,
        dosi_date_debut: null,
        dosi_date_fin: null,
        dosi_dose: 0,
      },
    };
  },
  mixins: [date],
  props: {
    collaborateur: {
      Type: Object,
      requierd: true,
      default: {},
    },
    avatar: {
      Type: Boolean,
      requierd: false,
      default: false,
    },
    infos: {
      Type: Boolean,
      requierd: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "affaires/data",
    }),
    ...mapMultiRowFields("dosimetrie", ["doses"]),
    ...mapMultiRowFields("affaires", ["affaires"]),
    dosesFiltered() {
      return this.doses.filter((dose) => dose.dosi_collaborateur_id === this.collaborateur.id);
    },
    affairesList() {
      return this.affaires.map((affaire) => {
        return {
          id: affaire.id,
          description: affaire.affaire_identifiant + " - " + affaire.affaire_libelle,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      _createDose: "dosimetrie/create",
      _deleteDose: "dosimetrie/delete",
    }),
    calc: function () {
      var fn = function (_doses, _dt1, _dt2) {
        var _cumulDose = 0;

        for (let index = 0; index < _doses.length; index++) {
          const dose = _doses[index];
          const dt = new Date(Date.parse(dose.mois.replace(/ /g, "T"))).getTime();

          if (dt >= _dt1 && dt <= _dt2) {
            if (dose.dosi_type_id === 1) _cumulDose = _cumulDose + dose.dosi_dose;
          }
        }

        return {
          cumul: _cumulDose,
        };
      };

      const mois = this.$moment();
      const dt1 = this.$moment(mois).subtract(12, "months").startOf("month").toDate().getTime();
      const dt2 = this.$moment(mois).endOf("month").toDate().getTime();

      let data = [];
      this.dosesFiltered.forEach((dose) => {
        data.push({
          mois: dose.dosi_date_fin,
          dosi_dose: dose.dosi_dose,
          dosi_type_id: dose.dosi_type_id,
        });
      });

      this.loading = true;

      this.$worker
        .run(fn, [data, dt1, dt2])
        .then((resp) => {
          this.cumul = resp.cumul;
          this.loading = false;
          this.$emit("endOfCalcul");
        })
        .catch(console.error);
    },
    createDose: function () {
      this.feedback = {};
      this.loading = true;

      this._createDose({
        dosi_affaire_id: this.newDose.dosi_affaire_id,
        dosi_collaborateur_id: this.collaborateur.id,
        dosi_dose: this.newDose.dosi_dose,
        dosi_date_debut: this.newDose.dosi_date_debut,
        dosi_date_fin: this.newDose.dosi_date_fin,
        dosi_type_id: this.newDose.dosi_type_id,
        dosi_commentaire: this.newDose.dosi_commentaire,
      })
        .then(() => {
          this.calc();
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.calc();
  },
};
</script>
<style lang="css">
.hover-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.active-item-light {
  background-color: rgba(0, 0, 0, 0.05);
}
.active-item-dark {
  background-color: rgba(0, 0, 0, 0.2);
}
.text-muted-graph {
  color: #b6bbbd;
}
.border-bottom-graph {
  border-bottom: 3px solid #eb9d00 !important;
}
.text-graph {
}
</style>
