<template>
  <router-link
    :to="{ name: 'crm_affaire_dosi', params: { id: data.id } }"
    class="d-flex align-items-center hover-item w-100 p-2 pl-3 pr-3 cursor-pointer"
    :class="[hoverClass, qwarkBorder]"
    :active-class="activeClass"
    tag="div"
    @click.native="$emit('itemSelected')"
  >
    <div v-if="data.affaire_activite_id && data.affaire_activite_id == 1">
      <div
        class="avatar mr-3 border-0"
        v-bind:style="{
          backgroundImage:'url(/affaires/1.png)',
          width: '2rem',
          height: '2rem'
        }"
      ></div>
    </div>
    <div v-if="data.affaire_activite_id && data.affaire_activite_id == 2">
      <div
        class="avatar mr-3 border-0"
        v-bind:style="{
          backgroundImage:'url(/affaires/2.svg)',
          width: '2rem',
          height: '2rem'
        }"
      ></div>
    </div>
    <div v-if="data.affaire_activite_id && data.affaire_activite_id == 3">
      <div
        class="avatar mr-3 border-0"
        v-bind:style="{
          backgroundImage:'url(/affaires/3.svg)',
          width: '2rem',
          height: '2rem'
        }"
      ></div>
    </div>
    <div v-if="data.affaire_activite_id && data.affaire_activite_id == 4">
      <div
        class="avatar mr-3 border-0"
        v-bind:style="{
          backgroundImage:'url(/affaires/4.png)',
          width: '2rem',
          height: '2rem'
        }"
      ></div>
    </div>
    <div v-if="data.affaire_activite_id && data.affaire_activite_id == 5">
      <div
        class="avatar mr-3 border-0"
        v-bind:style="{
          backgroundImage:'url(/affaires/4.png)',
          width: '2rem',
          height: '2rem'
        }"
      ></div>
    </div>
    <div class="flex-grow-1">
      <div class="d-flex align-items-center">
        <span>{{ data.affaire_identifiant }}</span>
        <span class="ml-2 badge badge-primary" v-if="data.affaire_new">Nouveau</span>
      </div>
      <div style="line-height:1rem;">
        <div class="text-muted text-truncate" style="max-width:270px;" v-if="data.affaire_libelle">
          <small>{{ data.affaire_libelle }}</small>
        </div>
        <div class="text-muted text-truncate" style="max-width:270px;" v-if="data.affaire_libelle">
          <span class="mr-2">
            <small>prev. : {{ getEdpAffaire() }} mSv</small>
          </span>
          <span>
            <small>collectif : {{ getDoseAffaire() }} mSv</small>
          </span>
        </div>
        <div class="text-muted text-truncate" style="max-width:270px;" v-if="!data.affaire_libelle">
          <small>Aucun libellé</small>
        </div>
        <div class="text-truncate text-muted" style="max-width:300px;" v-if="data.affaire_comptes">
          <small>{{ data.affaire_comptes.description }}</small>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <BaseIcon
        name="circle"
        width="10"
        height="10"
        class="text-primary m-auto"
        fill="#007bff"
        v-if="data.affaire_etat_id === 1"
      ></BaseIcon>
      <BaseIcon
        name="circle"
        width="10"
        height="10"
        class="text-warning m-auto"
        fill="#ffc107"
        v-if="data.affaire_etat_id === 2"
      ></BaseIcon>
      <BaseIcon
        name="circle"
        width="10"
        height="10"
        class="text-success m-auto"
        fill="#28a745"
        v-if="data.affaire_etat_id === 3"
      ></BaseIcon>
      <BaseIcon
        name="circle"
        width="10"
        height="10"
        class="text-success m-auto"
        fill="#dc3545"
        color="#dc3545"
        v-if="data.affaire_etat_id === 4"
      ></BaseIcon>
    </div>
  </router-link>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: { BaseIcon },
  props: {
    data: {
      Type: Object,
      required: false
    }
  },
  methods: {
    getDoseAffaire: function() {
      if (!this.data.affaire_dosimetrie) return 0;

      let rep = 0;
      let doses = [];

      doses = this.data.affaire_dosimetrie;
      doses.forEach(dose => {
        rep = rep + parseFloat(dose.dosi_dose);
      });
      return rep.toFixed(3);
    },
    getEdpAffaire: function() {
      if (!this.data.affaire_edp) return 0;

      let rep = 0;
      let edps = [];

      edps = this.data.affaire_edp;
      edps.forEach(edp => {
        if (edp.phases) {
          edp.phases.forEach(phase => {
            rep =
              rep +
              parseFloat(phase.ded) *
                parseFloat(phase.temps) *
                parseFloat(phase.coef) *
                parseFloat(phase.nbre_ope) *
                parseFloat(phase.iteration);
          });
        }
      });
      return rep.toFixed(3);
    }
  },
  computed: {
    activeClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "active-item-light text-danger";
      } else {
        return "active-item-dark text-warning";
      }
    },
    hoverClass: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
    qwarkBorder: function() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    }
  },
  mounted() {}
};
</script>
<style lang="css">
.hover-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.active-item-light {
  background-color: rgba(0, 0, 0, 0.05);
}
.active-item-dark {
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
